import React from "react";

import LayoutGeneral from "../components/LayoutGeneral";
import Section from "../components/Section";
import SectionRow from "../components/SectionRow";
import SVGCurve from "../components/SVGCurve";
import SEO from "../components/SEO";
import ImageDiv from "../components/ImageDiv";
import TextDiv from "../components/TextDiv";
import TextParagraph from "../components/TextParagraph";
import ExternalLink from "../components/ExternalLink";
import AppointmentDiv from "../components/AppointmentDiv";
import UnorderedList from "../components/UnorderedList";
import { StaticImage } from "gatsby-plugin-image";
import DivWithText from "../components/DivWithText";

function AboutPage() {
  return (
      <LayoutGeneral>
        <SEO
            keywords={[`Agile Psychiatry`, `Brian Nahas MD`, `Psychiatrist`]}
            title="About Brian Nahas, MD"
            description="Brian Nahas, MD is a board-certified psychiatrist who provides
            medication management and counseling at Agile Psychiatry."
        />

          <Section shade="mid">
              <SectionRow>
                  <ImageDiv className="w-3/4 md:w-1/3 mt-4">
                      <StaticImage src="../images/card.profile.jpg" className="shadow-lg object-cover" alt="An image of Brian Nahas, MD" imgClassName="rounded-lg"/>
                  </ImageDiv>
                  <TextDiv className="md:w-2/3">
                      <h2 className="text-3xl text-center">Brian Nahas, MD</h2>
                      <h4 className="text-xl pb-4 text-center italic">Board Certified Psychiatrist</h4>
                      <TextParagraph className="mb-8">
                          Dr. Nahas is a board-certified psychiatrist with extensive experience in medication management
                          and various forms of psychotherapy. He completed his residency training at the <ExternalLink to="https://www.utsouthwestern.edu/">University of
                          Texas Southwestern</ExternalLink> in Dallas where he refined his medical expertise in the halls of
                          Parkland Hospital, Zale Lipshy University Hospital, Clements University Hospital, the Dallas VA,
                          and Children&lsquo;s Medical Center Dallas.
                          He trained in psychotherapy under the tutulage of classically trained psychoanalysts associated with the
                          Dallas Psychoanalytic Institute. Dr. Nahas has experience treating adults, children, and the
                          elderly among a diverse population.
                      </TextParagraph>
                      <TextParagraph className="mb-8">
                          Prior to residency, Dr. Nahas attended <ExternalLink to="https://med.uth.edu/">McGovern Medical
                          School</ExternalLink> in the <ExternalLink to="https://www.tmc.edu/">Texas Medical Center</ExternalLink>
                          &nbsp;on the Lawrence Baker Scholarship, completing rotations at MD Anderson, Memorial Hermann,
                          and LBJ County Hospital.
                      </TextParagraph>
                  </TextDiv>
              </SectionRow>
          </Section>
          <SVGCurve direction="right-slope" transition="mid-light"/>
          <Section shade="light">
              <SectionRow>
                  <TextDiv>
                      <h2 className="text-3xl text-center mb-4">Associations</h2>
                      <DivWithText className="mb-8">
                          <UnorderedList>
                              <li>Diplomat of the American Board of Psychiatry and Neurology</li>
                              <li>Member of the American Psychiatric Association</li>
                              <li>Member of the Texas Society of Psychiatric Physicians</li>
                              <li>Member of the American Medical Association</li>
                              {/*<li>Member of the Texas Medical Association</li>*/}
                              {/*<li>Member of the Texas County Medical Society</li>*/}
                          </UnorderedList>
                      </DivWithText>
                  </TextDiv>
              </SectionRow>
          </Section>
          <SVGCurve direction="right-slope" transition="light-dark"/>
          <Section shade="dark">
              <SectionRow>
                  <AppointmentDiv/>
              </SectionRow>
          </Section>
      </LayoutGeneral>
  );
}

export default AboutPage;
