import PropTypes from "prop-types";
import React from "react";

function ImageDiv({children, className}) {
    let classNameString = "text-xl text-center justify-center mx-auto rounded px-10 pt-10 md:p-10 " + className
    return (
        <div className={classNameString}>
            {children}
        </div>
    )
}

ImageDiv.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
}

export default ImageDiv;